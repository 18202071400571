
import { USER_GETTERS } from '@/store/user/getters';
import { Phase, Program } from '@/types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { format, } from 'date-fns'
import nlBE from 'date-fns/locale/nl-BE';
import fr from 'date-fns/locale/fr';

@Component({
  components: {
    ProgramTypeSpan: () => import('@/components/typography/ProgramTypeSpan.vue'),
    ProgramFormulaSpan: () => import('@/components/typography/ProgramFormulaSpan.vue'),
  }
})
export default class ProgramInfoCard extends Vue {
  @Prop({ required: true }) program!: Program;
  @Prop({ required: true }) phases!: Phase[];

  get user() {
    return this.$store.getters[USER_GETTERS.ACTING_USER]
  }

  formatDate(date: Date) {
    return format(date, 'dd MMM yyyy', {
      locale: this.user.language === 'nl-be' ? nlBE : fr
    })
  }

  get currentPhase() {
    return this.phases.find(phase => phase.start.toDate() <= new Date() && phase.end.toDate() >= new Date())
  }
}
